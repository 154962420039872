import React from 'react';

import Link from 'next/link';

import useTranslateLpc from '@components/common/hooks/useTranslateLpc';
import Text from '@components/common/Text/Text';
import { personRoles } from '@models/PotModel/personRoles';
import { getRoute, ROUTE } from '@services/http/Route';
import { formatCurrency } from '@services/utils/StringService';

import css from './SubNav.module.scss';

type SubNavProps = {
    potTitle: string;
    amountLeft?: number;
    collectedAmount?: number;
    showTotalContribution?: boolean;
    personRole: string;
    potRef: string;
};

const SubNav = ({ potTitle, amountLeft, showTotalContribution, personRole, collectedAmount, potRef }: SubNavProps) => {
    const { t } = useTranslateLpc('lpc-navbar');

    return (
        <div className={`${css.sub}`}>
            <Link href={getRoute(ROUTE.LPC.POT.POT, potRef)}>
                <Text variant={'body_02'} color={'white'}>
                    {potTitle}
                </Text>
            </Link>
            {amountLeft && personRole !== personRoles.PARTICIPANT && (
                <div className={css.sub__amount}>
                    <Text customClass={css.sub__amount__txt} variant={'body_02'} color={'white'}>
                        {t('amount-left')}
                    </Text>
                    <Text variant={'body_02'} color={'ui-success'}>
                        {formatCurrency(amountLeft)}
                    </Text>
                </div>
            )}

            {collectedAmount > 0 && (showTotalContribution || personRole === personRoles.ORGANISER) && (
                <div className={css.sub__amount}>
                    <Text customClass={css.sub__amount__txt} variant={'body_02'} color={'white'}>
                        {t('amount-left')}
                    </Text>
                    <Text variant={'body_02'} color={'ui-success'}>
                        {formatCurrency(collectedAmount)}
                    </Text>
                </div>
            )}
        </div>
    );
};

export default SubNav;
