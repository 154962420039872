import React from 'react';

import { useRouter } from 'next/router';

import Button from '@components/common/Button/Button';
import useTranslateLpc from '@components/common/hooks/useTranslateLpc';
import Modal from '@components/common/Modals/Modal/Modal';
import Text from '@components/common/Text/Text';
import AuthService from '@services/domain/Lpc/AuthService';
import { getRoute, ROUTE } from '@services/http/Route';

import css from './MangoPayCguModal.module.scss';

enum modalViews {
    MAIN_VIEW = 1,
    REJECT_TOS_VIEW = 2,
}

enum MangoPayCguStatus {
    ACCEPTED = 'ACCEPTED',
    REFUSED = 'REFUSED',
}

type MangoPayCguModalProps = {
    isVisible: boolean;
    onHide: () => void;
};

const MangoPayCguModal = ({ isVisible, onHide }: MangoPayCguModalProps) => {
    const { t } = useTranslateLpc(['common']);
    const router = useRouter();

    const getWording = (view: number) => {
        return {
            title: t(`mangopay-cgu-modal.${view}.title`),
            text: [0, 1, 2, 3].map((elem) =>
                t(`mangopay-cgu-modal.${view}.text.${elem}`, {
                    termsLpc: ROUTE.LPC.CGU,
                    termsMangopay: ROUTE.LPC.MANGO_PAY_CGU,
                }),
            ),
            confirmLabel: t(`mangopay-cgu-modal.${view}.confirm`),
            rejectLabel: t(`mangopay-cgu-modal.${view}.reject`),
        };
    };
    const [view, setView] = React.useState<number>(modalViews.MAIN_VIEW);
    const [isConfirming, setIsConfirming] = React.useState<boolean>(false);
    const [isRejecting, setIsRejecting] = React.useState<boolean>(false);
    const [wording, setWording] = React.useState<any>(getWording(modalViews.MAIN_VIEW));

    const onConfirm = () => {
        setIsConfirming(true);
        AuthService.setMangoPayCguStatus(MangoPayCguStatus.ACCEPTED)
            .then(() => {
                router.push(getRoute(ROUTE.LPC.ACCEPT_MANGOPAY_CGU));
            })
            .finally(() => {
                setIsConfirming(false);
                onHide();
            });
    };

    const onReject = () => {
        setView(modalViews.REJECT_TOS_VIEW);
        setWording(getWording(modalViews.REJECT_TOS_VIEW));
    };

    const onConfirmReject = () => {
        setIsRejecting(true);
        AuthService.setMangoPayCguStatus(MangoPayCguStatus.REFUSED).finally(() => {
            setIsRejecting(false);
            onHide();
        });
    };

    return (
        <Modal
            hideCloseButton
            closeOnOverlayClick={false}
            visible={isVisible}
            onHide={() => {}}
            customClass={css.mangopay_cgu_modal_overlay}
        >
            <div className={css.mangopay_cgu_modal}>
                {view === modalViews.MAIN_VIEW && (
                    <div className={css.mangopay_cgu_modal__caption}>
                        <Text htmlText={t('mangopay-cgu-modal.1.important')} variant={'body_01'} color={'white'} html />
                    </div>
                )}
                <h5 autoFocus={true}>{wording.title}</h5>
                {[0, 1, 2, 3].map((item) => {
                    return (
                        <Text
                            key={item}
                            htmlText={wording.text[item]}
                            customClass={css.mangopay_cgu_modal__text}
                            variant={'body_01'}
                            color={'txt-primary'}
                            html
                        />
                    );
                })}

                <div className={css.mangopay_cgu_modal__buttons}>
                    <Button
                        variant={'primary'}
                        width={'100%'}
                        height={'60px'}
                        isLoading={view === modalViews.MAIN_VIEW ? isConfirming : isRejecting}
                        isDisabled={isConfirming || isRejecting}
                        onClick={() => (view === modalViews.MAIN_VIEW ? onConfirm() : onConfirmReject())}
                        customClass={css.mangopay_cgu_modal__confirm_button}
                    >
                        {view === modalViews.MAIN_VIEW ? wording.confirmLabel : wording.rejectLabel}
                    </Button>

                    <Button
                        variant={'secondary'}
                        width={'100%'}
                        height={'60px'}
                        isLoading={view === modalViews.MAIN_VIEW ? isRejecting : isConfirming}
                        isDisabled={isConfirming || isRejecting}
                        onClick={() => (view === modalViews.MAIN_VIEW ? onReject() : onConfirm())}
                        customClass={css.mangopay_cgu_modal__reject_button}
                    >
                        {view === modalViews.MAIN_VIEW ? wording.rejectLabel : wording.confirmLabel}
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default MangoPayCguModal;
