import React, { useState, useEffect, useCallback } from 'react';

import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import Link from 'next/link';
import { useToasts } from 'react-toast-notifications';

import Button from '@components/common/Button/Button';
import useTranslateLpc from '@components/common/hooks/useTranslateLpc';
import Spinner from '@components/common/Spinner/Spinner';
import Text from '@components/common/Text/Text';
import PdfGenerator from '@components/lpc/Navbar/PdfGenerator/PdfGenerator';
import SpendingWayDropdown from '@components/lpc/Navbar/SpendingWayDropdown/SpendingWayDropdown';
import UserOptions from '@components/lpc/Navbar/UserOptions/UserOptions';
import { personRoles } from '@models/PotModel/personRoles';
import { poolContributionType } from '@propTypes/potTypes';
import { principalType, userAccountType } from '@propTypes/userTypes';
import greetingCardService from '@services/domain/Lpc/GreetingCardService';
import { getRoute, ROUTE } from '@services/http/Route';

import css from './NavDesktop.module.scss';

type NavDesktopProps = {
    principal?: principalType;
    isPotPage?: boolean;
    isHomePage?: boolean;
    potTitle?: string;
    isGreetingCardPage?: boolean;
    isTitleVisible?: boolean;
    personRole?: string;
    userAccount?: userAccountType;
    potRef?: string;
    poolContribution?: poolContributionType;
    hideDropDown?: boolean;
    hideButtons?: boolean;
    showTitleInNavBar?: boolean;
    isOffered?: boolean;
    participateDisabled?: boolean;
    potStatus?: string;
    showParticipate?: boolean;
    potCategory?: string;
};

const NavDesktop = ({
    principal,
    isHomePage,
    isPotPage,
    potTitle,
    isTitleVisible,
    personRole,
    isGreetingCardPage,
    potRef,
    userAccount,
    hideDropDown,
    showTitleInNavBar,
    hideButtons,
    potCategory,
}: NavDesktopProps) => {
    const { t } = useTranslateLpc(['lpc-navbar', 'common', 'lpc-pot']);
    const { addToast } = useToasts();

    const [isNavBarActive, setIsNavBarActive] = useState(false);
    const [isCreatingPdf, setIsCreatingPdf] = useState(false);
    const navBarStyle = () => {
        let className = css.navbar;

        if (isHomePage || isPotPage || isGreetingCardPage) {
            isNavBarActive && (className += ' ' + css.navbar__active);
        }

        if (isGreetingCardPage) {
            className += ' ' + css.navbar__transparent;
        }

        className += ' ' + css.navbar__white;

        return className;
    };

    const subtitle =
        potCategory !== 'BEREAVEMENT' && potCategory !== 'SOLIDARITY_POOL'
            ? t('greeting-card.title')
            : t('greeting-card.title-bereavement');

    useEffect(() => {
        if (isPotPage) {
            isTitleVisible ? setIsNavBarActive(false) : setIsNavBarActive(true);
        }
    }, [isPotPage, isTitleVisible]);

    const changeBackground = useCallback(() => {
        if (!isPotPage) {
            window.scrollY >= 200 ? setIsNavBarActive(true) : setIsNavBarActive(false);
        }
    }, [isPotPage]);

    useEffect(() => {
        window.addEventListener('scroll', changeBackground);
        return () => window.removeEventListener('scroll', changeBackground);
    }, [changeBackground]);

    const generatePdfDocument = async () => {
        setIsCreatingPdf(true);
        const messageList = await greetingCardService.getAllMessages(potRef, 0, 500).catch((err) =>
            addToast(t(`errors.${err.code}`), {
                appearance: 'error',
                autoDismiss: true,
            }),
        );
        const blob = await pdf(
            <PdfGenerator potTitle={potTitle} subtitle={subtitle} messageList={messageList} />,
        ).toBlob();
        setIsCreatingPdf(false);
        saveAs(blob, potCategory !== 'BEREAVEMENT' ? 'LPC_carte_de_voeux' : 'LPC_carte_de_soutien');
    };

    return (
        <nav className={navBarStyle()}>
            <section className={css.navbar__left}>
                <Link href={getRoute(ROUTE.LPC.HOME)}>
                    <img
                        className={css.navbar__left__logo}
                        src="/front-static/icons/logo/lpc-with-txt.svg"
                        alt={'Le pot commun'}
                    />
                </Link>
            </section>
            <h4 className={`${css.navbar__title} ${isNavBarActive && css.navbar__title__visible}`}>
                {showTitleInNavBar && isNavBarActive && potTitle}
            </h4>
            {principal !== undefined && (
                <section className={css.navbar__right}>
                    {!hideDropDown && (
                        <>
                            <Link href={getRoute(ROUTE.LPC.HELP_CENTER.DETAILS)} className={css.navbar__need_help}>
                                <Text variant={'caption_01'} color={'txt-primary'}>
                                    {t('help-center')}
                                </Text>
                            </Link>
                            <SpendingWayDropdown />
                        </>
                    )}

                    {isGreetingCardPage && (
                        <>
                            <button className={css.navbar__right__download} onClick={() => generatePdfDocument()}>
                                {isCreatingPdf ? (
                                    <Spinner />
                                ) : (
                                    <img src="/front-static/icons/action/download-outline.svg" alt="download" />
                                )}
                            </button>

                            <Link href={getRoute(ROUTE.LPC.POT.POT, potRef)}>
                                <Button customClass={css.navbar__right__btn} variant={'primary'}>
                                    {t('get-pot')}
                                </Button>
                            </Link>
                        </>
                    )}
                    {!hideButtons && (
                        <>
                            {personRole !== personRoles.BENEFICIARY && (
                                <>
                                    {!isGreetingCardPage && (
                                        <Link href={getRoute(ROUTE.LPC.POT.CREATE_POT)}>
                                            <Button
                                                customClass={css.navbar__right__btn}
                                                variant={isHomePage ? 'tertiary-muted' : 'primary'}
                                            >
                                                {t('create-pot')}
                                            </Button>
                                        </Link>
                                    )}
                                </>
                            )}
                        </>
                    )}
                    <UserOptions userAccount={userAccount} principal={principal} />
                </section>
            )}
        </nav>
    );
};

export default NavDesktop;
