import React, { useEffect, useRef, useState } from 'react';

import useWindowSize from '@components/common/hooks/useWindowSize';
import { M_DEVICE, S_DEVICE } from '@components/lpc/Constants';
import { EXT_LINK } from '@services/http/ExtLink';

import css from './TrusBox.module.scss';

type TrustBoxProps = {
    dataTemplateId?: string;
    width?: string;
    height?: string;
    containerWidth?: string;
    customClass?: string;
};

const TemplatesTypes = {
    MINI: '53aa8807dec7e10d38f59f32',
    HORIZONTAL: '5406e65db0d04a09e042d5fc',
    MICRO_COMBO: '5419b6ffb0d04a076446a9af',
    MICRO_REVIEW_COUNT: '5419b6a8b0d04a076446a9ad',
};

const TrustBox = ({ dataTemplateId, width, height, containerWidth, customClass }: TrustBoxProps) => {
    const ref = useRef(null);
    const [windowSize, setWindowSize] = useState<string>('desktop');
    const { width: screenWidth } = useWindowSize();

    useEffect(() => {
        const script = document.createElement('script');
        script.src = '//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js';
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    useEffect(() => {
        if (screenWidth > M_DEVICE) {
            setWindowSize('desktop');
        } else if (screenWidth <= M_DEVICE && screenWidth > S_DEVICE) {
            setWindowSize('tablet');
        } else {
            setWindowSize('mobile');
        }
    }, [screenWidth]);

    useEffect(() => {
        // @ts-ignore
        if (window.Trustpilot) {
            // @ts-ignore
            window.Trustpilot.loadFromElement(ref.current, true);
        }
    }, [windowSize]);

    return (
        <a
            className={`${css.trust} ${customClass || ''}`}
            style={containerWidth && { width: `${containerWidth}` }}
            href={EXT_LINK.TRUSPILOT}
            target="_blank"
            rel="noreferrer"
        >
            <div
                ref={ref}
                className="trustpilot-widget"
                data-locale="fr-FR"
                data-template-id={
                    screenWidth > M_DEVICE ? dataTemplateId || TemplatesTypes.HORIZONTAL : TemplatesTypes.MICRO_COMBO
                }
                data-businessunit-id="5197a5f90000640005346d15"
                data-style-height={height || '100px'}
                data-style-width={width || '100%'}
                data-theme="light"
            />
        </a>
    );
};
export default TrustBox;
export { TemplatesTypes };
