import React, { useContext, useEffect, useState } from 'react';

import { useRouter } from 'next/router';
import { useToasts } from 'react-toast-notifications';

import CustomHead from '@components/common/CustomHead/CustomHead';
import useTranslateLpc from '@components/common/hooks/useTranslateLpc';
import useWindowSize from '@components/common/hooks/useWindowSize';
import ConfirmKycModal from '@components/common/Modals/ConfirmKycModal/ConfirmKycModal';
import CookiesModal from '@components/common/Modals/CookiesModal/CookiesModal';
import MangoPayCguModal from '@components/common/Modals/MangoPayCguModal/MangoPayCguModal';
import ValidateMissingInformationsForm from '@components/common/Modals/MissingInformationsModal/MissingInformationsModal';
import RedirectToTfaActivationModal from '@components/common/Modals/RedirectToTfaActivationModal/RedirectToTfaActivationModal';
import { M_DEVICE } from '@components/lpc/Constants';
import UserContext from '@components/lpc/Context/User/UserContext';
import Footer from '@components/lpc/Footer/Footer';
import NavDesktop from '@components/lpc/Navbar/NavDesktop/NavDesktop';
import NavMobile from '@components/lpc/Navbar/NavMobile/NavMobile';
import SubNav from '@components/lpc/Navbar/SubNav/SubNav';
import { poolContributionType, potPrivacyType } from '@propTypes/potTypes';
import { missingInformationsType, principalType } from '@propTypes/userTypes';
import AuthService from '@services/domain/Lpc/AuthService';
import NotificationsService from '@services/domain/Lpc/NotificationsService';
import { ROUTE } from '@services/http/Route';
import ObjectService from '@services/utils/ObjectService';

import css from './Layout.module.scss';

type LayoutProps = {
    children: React.ReactNode;
    hideNavbar?: boolean;
    hideFooter?: boolean;
    blueFooter?: boolean;
    hideDropDown?: boolean;
    hideButtons?: boolean;
    isHomePage?: boolean;
    isPotPage?: boolean;
    isGreetingCardPage?: boolean;
    potTitle?: string;
    isTitleVisible?: boolean;
    personRole?: string;
    showSubNav?: boolean;
    showParticipate?: boolean;
    amountLeft?: number;
    potRef?: string;
    poolContribution?: poolContributionType;
    privacy?: potPrivacyType;
    collectedAmount?: number;
    showTitleInNavBar?: boolean;
    isOffered?: boolean;
    participateDisabled?: boolean;
    metaTitle?: string;
    metaUrl?: string;
    metaDescription?: string;
    metaImage?: string;
    ogTitle?: string;
    ogDescription?: string;
    ogUrl?: string;
    ogImage?: string;
    potStatus?: string;
    commissionType?: string;
    potCategory?: string;
    showLandBot?: boolean;
    setOpenParticipate?: (boolean) => void;
    metaTwitterCard?: string;
    hideMangoPayCguModal?: boolean;
};

const Layout = ({
    metaTwitterCard,
    metaTitle,
    metaDescription,
    ogTitle,
    ogDescription,
    ogUrl,
    ogImage,
    hideNavbar,
    hideFooter,
    blueFooter,
    hideDropDown,
    children,
    isHomePage,
    isPotPage,
    potTitle,
    isTitleVisible,
    personRole,
    showSubNav,
    amountLeft,
    isGreetingCardPage,
    potRef,
    poolContribution,
    privacy,
    collectedAmount,
    showTitleInNavBar,
    isOffered,
    potStatus,
    showParticipate,
    hideButtons,
    potCategory,
    participateDisabled,
    showLandBot,
    setOpenParticipate,
    hideMangoPayCguModal,
}: LayoutProps) => {
    const { width } = useWindowSize();
    const { t } = useTranslateLpc('common');
    const { addToast } = useToasts();
    const router = useRouter();

    const [isMobileView, setIsMobileView] = useState<boolean>(null);
    const [principal, setPrincipal] = useState<principalType>(undefined);
    const [missingInformationsUser, setMissingInformationsUser] = useState<missingInformationsType>(undefined);
    const [displayMangopayCguModal, setDisplayMangopayCguModal] = useState<boolean>(false);

    const userContext = useContext(UserContext);
    const { getUserAccount, userAccount } = userContext;
    const [showKycConfirmModal, setShowKycConfirmModal] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        setPrincipal(AuthService.getAuth());
    }, [missingInformationsUser]);

    useEffect(() => {
        setMissingInformationsUser(AuthService.getMissingInformationsUser());
        setShowKycConfirmModal(
            AuthService.getKycCookie() === 'INDIVIDUAL_PERSON' && !(ROUTE.LPC.DASHBOARD.MY_KYC === router?.asPath),
        );
    }, []);

    useEffect(() => {
        setIsMobileView(width <= M_DEVICE);
    }, [width]);

    useEffect(() => {
        principal && getUserAccount();
    }, [principal]);

    useEffect(() => {
        userAccount?.showMangoPayCguModal && setDisplayMangopayCguModal(true);
    }, [userAccount]);

    const saveStatus = () => {
        setIsLoading(true);
        NotificationsService.confirmKycInfosAreUpToDate(userContext.uniq)
            .then(() => {
                addToast(t(`success.UPDATE_SUCCESS`), {
                    appearance: 'success',
                    autoDismiss: true,
                });
            })
            .catch((err) => err)
            .finally(() => {
                setShowKycConfirmModal(false);
                setIsLoading(false);
            });
        document.cookie = 'kyc-cookie' + '=; Max-Age=0';
    };

    return (
        <>
            <CustomHead
                isPotPage={isPotPage}
                title={metaTitle}
                ogTitle={ogTitle}
                description={metaDescription}
                ogDescription={ogDescription}
                ogImage={ogImage}
                ogUrl={ogUrl}
                showLandBot={showLandBot}
                metaTwitterCard={metaTwitterCard}
            />
            <>
                {missingInformationsUser != null && <ValidateMissingInformationsForm />}
                {displayMangopayCguModal && !hideMangoPayCguModal && (
                    <MangoPayCguModal
                        onHide={() => {
                            setDisplayMangopayCguModal(false);
                            getUserAccount();
                        }}
                        isVisible={displayMangopayCguModal}
                    />
                )}
                {!router.asPath.startsWith(ROUTE.LPC.AUTH.TFA_UPDATE) &&
                    !router.asPath.startsWith(ROUTE.LPC.AUTH.SIGN_IN) &&
                    ObjectService.hasContent(userAccount) &&
                    userAccount.needActivateTfaPE && <RedirectToTfaActivationModal />}
                <div className={css.layout}>
                    <CookiesModal />
                    {!hideNavbar && !isMobileView && (
                        <NavDesktop
                            principal={principal}
                            isHomePage={isHomePage}
                            isPotPage={isPotPage}
                            isGreetingCardPage={isGreetingCardPage}
                            potTitle={potTitle}
                            isTitleVisible={isTitleVisible}
                            personRole={personRole}
                            potRef={potRef}
                            userAccount={userAccount}
                            poolContribution={poolContribution}
                            hideDropDown={hideDropDown}
                            hideButtons={hideButtons}
                            showTitleInNavBar={showTitleInNavBar}
                            isOffered={isOffered}
                            showParticipate={showParticipate}
                            potStatus={potStatus}
                            potCategory={potCategory}
                            participateDisabled={participateDisabled}
                        />
                    )}
                    {!hideNavbar && isMobileView && (
                        <NavMobile
                            principal={principal}
                            potRef={potRef}
                            potTitle={potTitle}
                            personRole={personRole}
                            isGreetingCardPage={isGreetingCardPage}
                            poolContribution={poolContribution}
                            isOffered={isOffered}
                            potStatus={potStatus}
                            hideButtons={hideButtons}
                            showParticipate={showParticipate}
                            potCategory={potCategory}
                            participateDisabled={participateDisabled}
                            setOpenParticipate={(value) => setOpenParticipate(value)}
                        />
                    )}
                    {showSubNav && (
                        <SubNav
                            potTitle={potTitle}
                            amountLeft={amountLeft}
                            showTotalContribution={privacy?.showTotalContribution}
                            personRole={personRole}
                            collectedAmount={collectedAmount}
                            potRef={potRef}
                        />
                    )}
                    {showKycConfirmModal && ObjectService.hasContent(userAccount) && (
                        <ConfirmKycModal
                            isVisible={showKycConfirmModal}
                            firstName={userAccount.firstName}
                            lastName={userAccount.lastName}
                            birthday={userAccount.birthday}
                            category={userAccount.cspCode}
                            onConfirm={() => saveStatus()}
                            isLoading={isLoading}
                        />
                    )}
                    <main>{children}</main>

                    {!hideFooter && !blueFooter && <Footer />}
                    {!hideFooter && blueFooter && (
                        <div style={{ backgroundColor: '#f2f7fc', marginTop: '-76px', marginBottom: '-30px' }}>
                            <Footer />
                        </div>
                    )}
                </div>
            </>
        </>
    );
};

export default Layout;
